
/* eslint-disable no-undef */
import { ref, onMounted } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAPS_API_KEY = "AIzaSyDAbjI1pP1q2NjkUYZcyfWjpxhV14hpYMA";

export default {
  name: "App",
  setup() {
    const currPos = ref({
      lat: 21.0362368,
      lng: 105.7905825,
    });
    const otherPos = ref(null);
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });
    let mapDiv = ref<HTMLDivElement>();
    let map: google.maps.Map;
    let clickListener: any;
    const originAutocomplete = ref();
    onMounted(async () => {
      await loader.load();
      originAutocomplete.value = new google.maps.places.Autocomplete(
        document.getElementById("address") as HTMLInputElement,
        {
          bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(54.4215296, -75.6971931)
          ),
        }
      );
      originAutocomplete.value.addListener("place_changed", () => {
        let place = originAutocomplete.value.getPlace().geometry.location;
        currPos.value = { lat: place.lat(), lng: place.lng() };
        new google.maps.Marker({
          position: currPos.value,
          label: "T",
          map: map,
        });
        map.setCenter(currPos.value);
      });
      if (mapDiv.value) {
        map = new google.maps.Map(mapDiv.value, {
          center: currPos.value,
          zoom: 14,
        });
      }

      map.addListener("click", ({ latLng: { lat, lng } }) => {
        currPos.value = { lat: lat(), lng: lng() };
        new google.maps.Marker({
          position: currPos.value,
          label: "T",
          map: map,
        });
      });
    });

    return { currPos, otherPos, mapDiv };
  },
};
